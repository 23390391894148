import { FormInstance } from 'antd';
import dayjs from 'dayjs';
import { IForemanInfo } from 'models';

import { suppliesPrice } from './suppliesPrice';

const c = (label?: string) => {
	if (label) {
		const [hours, minutes] = label.split(':').map(Number);
		return (hours * 60 + minutes) / 60;
	} else {
		return 0;
	}
};

interface ForemanInfoForm extends Partial<IForemanInfo> {
	loading_movers?: number;
	unloading_movers?: number;
}

export const calculator = (
	foreman_info: ForemanInfoForm,
	date: string,
	form: FormInstance
) => {
	const {
		workers,
		truck_fee,
		driving_time_fee,
		driving_time_price,
		double_drive,
		tips,
		loading_time,
		drive_time,
		unloading_time,
		loading_movers,
		unloading_movers,
		heavy_items,
		add_items,
		payment
	} = foreman_info;
	const dayType = dayjs(date).day() < 5 ? 0 : 10;
	const moversPrice = 30 + Number(workers?.length) * 40;
	const truckFee = truck_fee ? 50 : 0;
	const drivingFee = driving_time_fee ? driving_time_price : 0;
	const doubleDrive = double_drive ? 2 : 1;
	const tipsAmount = tips ? tips : 0;
	const totalTime =
		c(loading_time) + c(drive_time) * doubleDrive + c(unloading_time);
	const loadingMovers = loading_movers ? loading_movers * 40 : 0;
	const unloadingMovers = unloading_movers ? unloading_movers * 40 : 0;
	form.setFieldsValue({ acf: { foreman_info: { total_time: totalTime } } });
	const heavyItems = heavy_items
		? heavy_items?.reduce((total, el) => {
			return total + +el?.heavy_item_price;
		  }, 0)
		: 0;
	const addItems = add_items
		? add_items.reduce((total, el) => {
			const price = suppliesPrice(el?.add_item);
			return total + el?.add_item_count * price;
		  }, 0)
		: 0;
	const paymentMethod = payment === 'cash' ? 0 : 10;
	const totalMoversPrice = moversPrice + paymentMethod + dayType;
	const totalTimePrice =
		(totalMoversPrice + loadingMovers) * c(loading_time) +
		c(drive_time) * doubleDrive * totalMoversPrice +
		(totalMoversPrice + unloadingMovers) * c(unloading_time);
	const itemPlusFee =
		Number(truckFee) + Number(drivingFee) + heavyItems + addItems + tipsAmount;
	const result = totalTimePrice + itemPlusFee;
	form.setFieldsValue({
		acf: { foreman_info: { total: isNaN(result) ? 0 : Math.round(result) } }
	});
};
