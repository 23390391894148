import { CopyOutlined } from '@ant-design/icons';
import { Button, Drawer, Flex, Popconfirm } from 'antd';
import copy from 'copy-to-clipboard';
import dayjs from 'dayjs';
import { useWorkMutation } from 'hooks';
import { IWork } from 'models';
import { useRef } from 'react';
import { SlotInfo } from 'react-big-calendar';
import { copyOrderInfo } from 'utils';

import { WorkTitle } from './WorkTitle';
import { CreateWorkForm, CreateWorkFormRef } from './forms';

interface MyModalProps {
	work: IWork | null;
	slot?: SlotInfo | null;
	handleCancel: () => void;
	queryKey: any[];
}

export const MyModal = ({
	work,
	handleCancel,
	slot,
	queryKey
}: MyModalProps) => {
	const formRef = useRef<CreateWorkFormRef>();
	const { updateWork, deleteWork, messageApi, contextHolder } = useWorkMutation(
		{
			queryKey,
			work,
			onSettled: () => handleCancel(),
			onSettledDelete: () => handleCancel()
		}
	);

	const onFinish = (values: Partial<IWork>) => {
		if (values.acf) {
			values.acf.date = dayjs(values.acf.date).format('YYYYMMDD');
			updateWork.mutate(values);
		}
	};

	const onDeleteJob = () => {
		deleteWork.mutate();
	};

	const copyToClickBoard = () => {
		const values = formRef.current?.getValues();
		if(values) {
			copy(copyOrderInfo(values));
			messageApi.success('Copied!');
		}
	};

	return (
		<Drawer
			destroyOnClose
			title={work ? <WorkTitle work={work} /> : 'Add new work'}
			size="large"
			open={!!work || !!slot}
			onClose={handleCancel}
			footer={
				<Flex gap={8}>
					<Button key="cancel" onClick={handleCancel} size="large">
						Cancel
					</Button>
					,
					<Popconfirm
						title="Are you sure to delete this job?"
						onConfirm={onDeleteJob}
						okText="Yes"
						cancelText="No"
						key="delete"
					>
						<Button
							loading={deleteWork.isPending}
							danger
							type="primary"
							size="large"
						>
							Delete
						</Button>
					</Popconfirm>
					,
					<Button
						loading={updateWork.isPending}
						key="submit"
						type="primary"
						onClick={() => {
							formRef.current?.submitForm();
						}}
						size="large"
					>
						Submit
					</Button>
				</Flex>
			}
		>
			{work && (
				<Button
					onClick={copyToClickBoard}
					type="primary"
					style={{ width: '100%', marginBottom: 15 }}
					size="large"
					icon={<CopyOutlined />}
				>
					Copy order to clipboard
				</Button>
			)}
			<CreateWorkForm
				work={work}
				onFinish={onFinish}
				slot={slot}
				queryKey={queryKey}
				ref={formRef}
			/>
			{contextHolder}
		</Drawer>
	);
};
